import React from "react";
import SEO from "../../components/seo";
import useProfilePic from "../../hooks/useProfilePic";
import ProfilePage from "../../layouts/ProfilePage";

const Danielle = () => {
	const { danielle: pic } = useProfilePic();
	return (
		<>
			<SEO
				title="Our Team of Specialists"
				keywords={[`Gastroenterologist`, `Dr Danielle Lam`]}
				description="About Dr Danielle Lam"
			/>
			<ProfilePage
				title="Dr Danielle Lam - Gastroenterologist"
				name="Dr Danielle Lam"
				img={pic.childImageSharp.fixed}
				quote=""
				specialty="All aspects of Inflammatory bowel disease, luminal and general gastroenterology and general endoscopy"
				education={[
					"Undergraduate: University of Western Australia",
					"Postgraduate GI Training: Sir Charles Gairdner Hospital, Perth, Western Australia",
					"Postgraduate GI Training: Royal Perth Hospital, Perth, Western Australia",
					"Postgraduate GI Training: Fiona Stanley Hospital, Perth, Western Australia",
					"Fellowship: Royal Perth Hospital, Perth, Western Australia",
					"Fellowship: Addenbrooke’s Hospital, Cambridge, United Kingdom",
				]}
				description={[
					"Dr Danielle Lam is a gastroenterologist who completed her training in Perth and Cambridge (UK), specialising in inflammatory bowel disease and luminal gastroenterology. Danielle takes great pride in her work and is committed to providing a high standard of holistic care in an empathetic, effective and professional manner.",
					"Danielle completed a Bachelor of Medicine and Surgery from the University of Western Australia. After completing her internship at Sir Charles Gairdner Hospital, she subsequently underwent specialty training in gastroenterology at Fiona Stanley Hospital, Sir Charles Gairdner Hospital and Royal Perth Hospital. Following the completion of her training, she undertook an advanced fellowship in inflammatory bowel disease, working alongside several world leading experts at Addenbrooke’s Hospital in Cambridge, UK. She has further trained in the use of ultrasound in the care of inflammatory bowel disease through specialised gastroenterology clinics located in Copenhagen (Denmark) and Adelaide. ",
					"Danielle is committed to continuing professional development, teaching and training of medical students and doctors in gastroenterology. She is involved in the education of medical students at University of Western Australia and trainee doctors at Sir Charles Gairdner Hospital.",
					"Her special interests include inflammatory bowel disease, coeliac disease, functional gut disorders, nutrition and eosinophilic oesophagitis. She is also committed to providing high quality endoscopic procedures (gastroscopy and colonoscopy) and is experienced in dye-spray chromoendoscopy. She has also completed specialist training in intestinal ultrasound and is a member of the International Bowel Ultrasound group.",
				]}
				honours={[
					"Above and Beyond Consumer Excellence Award – Royal Perth Hospital, 2019",
				]}
				background="Danielle completed a Bachelor of Medicine and Surgery from the University of Western Australia and then completed her gastroenterology training in Perth. She subsequently undertook fellowship training at Addenbrooke’s Hospital in Cambridge UK in inflammatory bowel disease before returning to Perth and being appointed as a Consultant Gastroenterologist at Sir Charles Gairdner Hospital."
				appointments={[
					"Private appointment - Hollywood Private Hospital, Perth",
					"Public appointment - Osborne Park Hospital, Perth",
					"Public appointment - Sir Charles Gairdner Hospital, Perth",
				]}
				memberships={[
					"Gastroenterological Society of Australia (GESA)",
					"Australian Health Practitioner Regulation Agency (AHPRA)",
					"The Royal Australasian College of Physicians (RACP)",
					"Australian Medical Association (AMA)",
					"International Bowel Ultrasound group (IBUS)",
					"European Crohn’s and Colitis Organisation (ECCO)",
					"British Society of Gastroenterologists (BSG)",
					"United European Gastroenterology (UEG)",
				]}
				personal={[
					"I enjoy travel, hiking and testing out new recipes in the kitchen.",
				]}
				publications={[
					{
						publicationTitle: "Differential efficacy of biologic agents for Inflammatory Bowel Disease Unclassified and comparison with Ulcerative Colitis: a propensity score analysis of 4054 patients from the UK IBD Bioresource.",
						authors: "C Kapizioni, R Desoki, K Balendran, D Lam, R Shawky, L Pele, M Parkes, T Raine",
					},
					{
						publicationTitle: "Pregnancy and birth outcomes in women with Inflammatory Bowel Disease during the biologic era – experiences from a Western Australian tertiary centre between 2012– 2021",
						authors: "D Lam, S Picardo, S Menon, K So, K Venugopal",
					},
					{
						publicationTitle: "Zenker Symptom Score (ZSS): an effective clinical assessment tool for Zenker’s Diverticulum.",
						authors: "D Lam, S Raftopoulos",
					},
					{
						publicationTitle: "Impact of modern antiviral therapy of chronic Hepatitis B and C on clinical outcomes of liver disease. World Journal of Gastroenterology.",
						authors: "Khoo T, Lam D, Olynyk J",
					},
					{
						publicationTitle: "Fibromuscular dysplasia presenting with a deep vein thrombosis.",
						authors: "Lam D, Jansen S, Tibballs J, A McLean-Tooke",
					},
					{
						publicationTitle: "Case study of Idiopathic anaphylaxis and multidisciplinary team approach to management.",
						authors: "B Knezevic ,  D Lam,  A Buckland,  A Juniper,  S Hussain,  A Brusch",
					},
					{
						publicationTitle: "Red blood cell transfusion in the cirrhotic patient with gastrointestinal bleeding.",
						authors: "D Lam, J Olynyk",
					},
					{
						publicationTitle: "Attitudes of expectant fathers in the setting of teenage pregnancy towards breastfeeding - a mixed methods study.",
						authors: "D Lam,  RW Petersen RW, JA Quinlivan",
					},
					{
						publicationTitle: "The North Metropolitan Perth Gestational Diabetes Cohort: The IADPSG diagnostic criteria for gestational diabetes mellitus identify a group of women with higher rates of persisting glucose intolerance and type 2 diabetes mellitus.",
						authors: "JA Quinlivan, D Lam, RA Petersen",
					},
					{
						publicationTitle: "Cholesterol Abnormalities are Common in Women with Prior Gestational Diabetes.",
						authors: "JA Quinlivan, D Lam",
					},
				]}
			/>
		</>
	);
};

export default Danielle;
